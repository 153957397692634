/* eslint-disable */
import css from 'styled-jsx/css';

import COLORS from '@utils/colors';

const styles = css`
  .icon-pin {
    path {
      fill: ${COLORS.black900};
    }

    &.color-white {
      path {
        fill: ${COLORS.white};
      }
    }

    &.color-black600 {
      path {
        fill: ${COLORS.black600};
      }
    }

    &.color-blue {
      path {
        fill: ${COLORS.primary};
      }
    }
  }
`;

export default styles;
