import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';
import css from 'styled-jsx/css';

const { desktopL } = BREAKPOINT;

const styles = css`
  .auto-suggest-container {
    position: sticky;
    top: 64px;
    z-index: 45;
    width: 100%;
    margin: auto;

    &.has-background {
      padding: 8px 16px;
      background: ${COLORS.purple900};
      @media screen and (min-width: ${desktopL}px) {
        display: flex;
        justify-content: center;
        .auto-suggest-form {
          max-width: 822px;
        }
      }
    }

    .auto-suggest-form {
      display: flex;
      align-items: center;
      border-radius: 60px;
      background-color: ${COLORS.white};
      box-shadow: 0 4px 4px -2px rgba(24, 39, 75, 0.08);
      @media screen and (min-width: ${desktopL}px) {
        padding: 4px;
        width: 822px;
      }
      .auto-suggest-form-area {
        position: relative;
        flex-grow: 1;
        @media screen and (max-width: ${desktopL}px) {
          width: 100%;
        }

        :global(.form-input) {
          margin-bottom: 0;
        }
      }
      :global(.text-toggle-button) {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        height: calc(100% - 2px);
        margin: auto;
        padding-right: 36px;
        background: ${COLORS.white};
        border-radius: 20px;
      }

      .auto-suggest-form-date {
        flex-grow: 1;
        :global(.form-input) {
          margin-bottom: 0;
        }
      }
      .auto-suggest-form-guest {
        margin-right: 4px;
        :global(input[name='guests']) {
          margin-bottom: 0;
        }
      }
      .auto-suggest-form-guest-count {
        flex-grow: 1;
        margin-right: 16px;
        :global(.form-input) {
          margin-bottom: 0;
        }
      }
      .auto-suggest-form-date-calendar {
        :global(.modal-container) {
          width: 814px;
          :global(.modal) {
            border-radius: 24px;
            :global(.calendar-contents) {
              /* 14px is the decreased height of date cell */
              height: calc(470px - (14px * 5) - 36px);
              :global(.DayPicker_portal__horizontal) {
                padding-top: 12px;
              }
              :global(.DayPicker_weekHeader) {
                top: 55px;
              }
              :global(.DayPickerNavigation__horizontal) {
                display: flex;
                justify-content: space-between;
                width: 94%;
                margin: 0 auto;
              }
              :global(.CalendarMonth_caption) {
                padding-top: 9px;
                padding-bottom: 45px;
              }
            }
          }
        }
        :global(.modal-footer) {
          z-index: 2;
        }
      }
      :global(.input-wrapper) {
        @media screen and (max-width: ${desktopL}px) {
          height: 36px;
        }
        padding: 9px 8px 9px 40px;
        color: ${COLORS.black900};
        border-radius: 24px;

        :global(.input-icon) {
          left: 8px !important;
          @media screen and (max-width: ${desktopL}px) {
            height: 20px;
            width: 20px !important;
            margin: 8px 0;
          }
          :global(svg) {
            @media screen and (max-width: ${desktopL}px) {
              height: 20px !important;
              width: 20px !important;
            }
            :global(path) {
              fill: ${COLORS.black900};
            }
          }
        }

        :global(input) {
          padding: initial !important;
          padding-bottom: initial !important;
          padding-left: 0 !important;
          padding-right: 0 !important;
          border: none !important;
          font-size: 14px !important;
          font-weight: bold;

          @media screen and (max-width: ${desktopL}px) {
            line-height: 20px;
            font-size: 14px !important;
          }
        }

        &:focus-within {
          box-shadow: 0 0 0 2px ${COLORS.primary};
          background-color: ${COLORS.white};

          @media screen and (max-width: ${desktopL}px) {
            background-color: inherit;
            box-shadow: initial;
          }
        }
      }
      :global(.search-button) {
        padding: 9px 28px;
        border-radius: 24px;
        color: ${COLORS.white};
        background-color: ${COLORS.primary};
        border: 1px solid ${COLORS.primary};
        cursor: pointer;

        &:hover {
          background-color: ${COLORS.purple700};
        }
      }
      :global(input) {
        // In order to show input cursor
        border-radius: initial !important;
      }
    }
    .auto-suggest-form-date-calendar {
      position: absolute;
      top: 106%;
    }
    .auto-suggest-form-guest-counter {
      position: absolute;
      left: 50%;
      margin-left: 90px;
      top: 106%;

      :global(.modal-container) {
        :global(.modal) {
          border-radius: 24px;
        }
      }
    }
    :global(.CalendarDay__selected) {
      background: transparent !important;
    }

    :global(.CalendarDay__selected.CalendarDay__selected_start)::after,
    :global(.CalendarDay__selected.CalendarDay__selected_end)::after {
      width: calc(100% - 14px) !important;
      left: 7px !important;
    }
    :global(.CalendarDay__default) {
      height: 36px !important;
    }
    :global(.modal-contents) {
      padding-top: 24px;
    }
    :global(.calendar-contents) {
      height: auto !important;
    }
    :global(.DayPicker__horizontal) {
      margin-top: 0 !important;
      top: 0 !important;
      padding-top: 0 !important;
      position: relative;
    }
    :global(.CalendarMonthGrid_month__hidden) {
      height: 0 !important;
    }
    :global(.DayPicker_transitionContainer) {
      height: auto !important;
    }
    :global(.CalendarMonthGrid__horizontal) {
      position: relative !important;
    }
  }
`;

export default styles;
