import { useEffect, useState } from 'react';
import { SearchHistoryItem } from './types';

const SEARCH_HISTORY_LIMIT = 5;

const getSearchHistory = (): SearchHistoryItem[] => {
  if (typeof window === 'undefined') {
    return [];
  }

  const history: SearchHistoryItem[] = JSON.parse(
    localStorage.getItem('search_history') || '[]'
  );

  return history.filter(
    (element, index, selfArray) =>
      index ===
      selfArray.findIndex(
        (obj) => obj.id === element.id && obj.type === element.type
      )
  );
};

export const setSearchHistory = (item: SearchHistoryItem, itemType: string) => {
  if (typeof window === 'undefined') {
    return [];
  }

  const searchHistory = JSON.parse(
    localStorage.getItem('search_history') || '[]'
  );

  if (searchHistory.length > 0) {
    const isDuplicate = searchHistory.find(
      (sh: SearchHistoryItem) => sh.id === item.id && sh.type === item.type
    );

    if (isDuplicate) {
      return;
    }
  }

  searchHistory.unshift({ ...item, type: itemType });

  const value = JSON.stringify(searchHistory.slice(0, SEARCH_HISTORY_LIMIT));

  localStorage.setItem('search_history', value);
};

// hook
export const useSearchHistory = (
  getActivePropertiesAndAreas,
  locale: string | undefined
) => {
  const [searchHistories, setSearchHistories] = useState<SearchHistoryItem[]>(
    []
  );

  useEffect(() => {
    const update = async () => {
      const history = getSearchHistory();

      try {
        const propertyIds = history
          .filter((h) => h.type === 'Property')
          .map((h) => h.id);
        const areaIds = history
          .filter((h) => h.type === 'Area')
          .map((h) => h.id);
        const result = await getActivePropertiesAndAreas(
          propertyIds,
          areaIds,
          locale
        );

        if (!result) {
          return setSearchHistories([]);
        }

        // grab record from remote, if it doesn't exist or is inactive, don't show it in history
        const filteredHistory: SearchHistoryItem[] = history
          .map((h) => {
            if (h.type === 'Area') {
              return result.areas.find(
                (area: SearchHistoryItem) => area.id === h.id
              );
            }

            return result.properties.find(
              (property: SearchHistoryItem) => property.id === h.id
            );
          })
          .filter(Boolean);

        setSearchHistories(filteredHistory);
      } catch (error) {
        console.error(error);
      }
    };

    update();
  }, [JSON.stringify(getSearchHistory())]); // only run this if the history changes

  return [searchHistories];
};
