/* eslint-disable */
import classNames from 'classnames';
import React from 'react';

import { SVG_SIZES } from '@utils/constants';

import styles from './css';

type IconProps = {
  size?: 'sm' | 'md' | 'lg';
  color?: 'gray' | 'black600' | 'white' | 'blue';
  withFill?: boolean;
};

const IconPin = ({
  size = 'md',
  color = 'gray',
  withFill = false,
}: IconProps = {}) => {
  const style = {
    width: SVG_SIZES[size],
    height: SVG_SIZES[size],
  };

  return (
    <div>
      <svg
        style={style}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={classNames({ [`color-${color}`]: color }, 'icon-pin')}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 10.9995C11.173 10.9995 10.5 10.3265 10.5 9.4995C10.5 8.6725 11.173 7.9995 12 7.9995C12.827 7.9995 13.5 8.6725 13.5 9.4995C13.5 10.3265 12.827 10.9995 12 10.9995M12 5.9995C10.07 5.9995 8.5 7.5695 8.5 9.4995C8.5 11.4295 10.07 12.9995 12 12.9995C13.93 12.9995 15.5 11.4295 15.5 9.4995C15.5 7.5695 13.93 5.9995 12 5.9995M12 19.646C10.325 18.062 6 13.615 6 9.922C6 6.657 8.691 4 12 4C15.309 4 18 6.657 18 9.922C18 13.615 13.675 18.062 12 19.646M12 2C7.589 2 4 5.553 4 9.922C4 15.397 11.049 21.501 11.349 21.758C11.537 21.919 11.768 22 12 22C12.232 22 12.463 21.919 12.651 21.758C12.951 21.501 20 15.397 20 9.922C20 5.553 16.411 2 12 2"
          fill="#222222"
        />
        {withFill && (
          <path
            opacity="0.16"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 4C11.589 4 8 7.553 8 11.922C8 17.397 15.049 23.501 15.349 23.758C15.537 23.919 15.768 24 16 24C16.232 24 16.463 23.919 16.651 23.758C16.951 23.501 24 17.397 24 11.922C24 7.553 20.411 4 16 4Z"
            fill="black"
          />
        )}
      </svg>

      <style jsx>{styles}</style>
    </div>
  );
};

export default IconPin;
