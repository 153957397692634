import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Input from '@atoms/Input';
import InputStepper from '@atoms/InputStepper';
import Modal from '@atoms/Modal';
import { TypoXSBold } from '@atoms/Typos';
import { Device } from '@components/Layout';
import { useAppSelector } from '@hooks/useAppSelector';
import * as propertyHistoryActions from '@redux/modules/propertyHistory';
import * as searchActions from '@redux/modules/search';
import IconCalendar from '@svg-icons/IconCalendar';
import IconMultiUsers from '@svg-icons/IconMultiUsers';
import IconPin from '@svg-icons/IconPin';
import { MAX_GUEST_COUNT_FOR_SEARCH } from '@utils/constants';
import {
  useActions,
  useDisablePageScrollWhenShowModal,
  useHandleOutsideClick,
} from '@utils/hooks';
import routes, { route } from '@utils/routes';
import { setSearchHistory, useSearchHistory } from '@utils/search-history';
import { boundsQueryFromArea } from '@utils/search-map';
import { pushDataLayer } from '@utils/spa-ga4';
import { Area, DateRange, SearchProperty } from '@utils/types';
import {
  debounce,
  formatCalendarDate,
  getDefaultPrefecture,
} from '@utils/utils';
import classNames from 'classnames';
import moment from 'moment';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import styles from './css';
import { Property } from '@services/hafh/types/generated';

const AutoSuggestList = dynamic(() => import('@molecules/AutoSuggestList'));
const PropertiesSearchModal = dynamic(
  () => import('@molecules/PropertiesSearchModal')
);
const SearchCalendar = dynamic(() => import('@molecules/SearchCalendar'));
const SearchConditionButton = dynamic(
  () => import('@molecules/SearchConditionButton')
);

const AutoSuggestInHeader = ({
  hasBackground,
}: {
  hasBackground?: boolean;
}) => {
  const { t } = useTranslation('search-window');
  const { defaultLocale, isReady, locale, pathname, push, query } = useRouter();
  const {
    area_id,
    area_name: areaNameQuery,
    checkin,
    checkout,
    no_of_guest,
  } = query;
  const area_name = Array.isArray(areaNameQuery)
    ? areaNameQuery[0]
    : areaNameQuery;

  const locationSearchEnabled = !area_id && !area_name;

  const { search } = useAppSelector((state) => ({
    search: state.search,
  }));
  const { autoComplete, getActivePropertiesAndAreas, updateSearchResult } =
    useActions({
      ...searchActions,
      ...propertyHistoryActions,
    });

  const areaInputRef = useRef<HTMLInputElement>(null);

  const [locationQuery, setLocationQuery] = useState('');
  const [showSuggestResult, setShowSuggestResult] = useState(false);
  const [showSearchHistory, setShowSearchHistory] = useState(false);
  const [showAutoSuggestMobile, setShowAutoSuggestMobile] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showGuests, setShowGuests] = useState(false);
  const [noOfGuest, setNoOfGuest] = useState(0);
  const [showPropertiesSearchModal, setShowPropertiesSearchModal] =
    useState(false);
  const [undecidedDate, setUndecidedDate] = useState(false);
  const [currentItem, setCurrentItem] = useState<Area | SearchProperty>(null);
  const [currentItemType, setCurrentItemType] = useState<'Property' | 'Area'>(null);
  const [dateRange, setDateRange] = useState<DateRange>({
    endDate: null,
    startDate: null,
  });
  const [shortAreaName, setShortAreaName] = useState('');
  useDisablePageScrollWhenShowModal(showAutoSuggestMobile);

  const { endDate, startDate } = dateRange;

  const resetSearchResult = () => {
    autoComplete('', locale);
  };

  useEffect(() => {
    resetSearchResult();
  }, []);

  const onChangeAutoSuggestDebounced = useCallback(
    debounce(autoComplete, 250),
    []
  );

  const onChangeAreaAutoSuggest = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setLocationQuery(value);

    if (value.length === 0) {
      setShowSearchHistory(true);
      resetSearchResult();
    } else if (showSearchHistory) {
      setShowSearchHistory(false);
    }

    if (value.length < 2) {
      setShowSuggestResult(false);
      updateSearchResult();

      return;
    }

    onChangeAutoSuggestDebounced(value, locale);
    setShowSuggestResult(true);
  };

  useEffect(() => {
    if (undecidedDate) {
      setDateRange({
        endDate: null,
        startDate: null,
      });
      setUndecidedDate(true);

      return;
    }
    setUndecidedDate(false);
  }, [undecidedDate]);

  const [searchHistories] = useSearchHistory(
    getActivePropertiesAndAreas,
    locale
  );

  const pushToPropertiesPage = async (item: Area | SearchProperty, itemType: 'Property' | 'Area') => {
    const area = itemType === 'Area' ? item as Area : (item as SearchProperty).area;

    const boundsQueryObject = boundsQueryFromArea(area);
    delete query.fetch;
    delete query.page;
    delete query.checkin;
    delete query.checkout;
    delete query.no_of_guest;

    push(
      {
        pathname: routes.properties,
        query: {
          ...query,
          area_id: area?.id || area_id,
          area_name: area?.name || area_name,
          property_id: itemType === 'Property' ? item.id : undefined,
          amenity_tag_ids: undefined,
          property_type_tag_ids: undefined,
          max_coin: undefined,
          min_coin: undefined,
          ...(startDate && endDate
            ? {
                checkin: startDate.format('YYYY-MM-DD'),
                checkout: endDate.format('YYYY-MM-DD'),
              }
            : null),
          ...(noOfGuest && { no_of_guest: noOfGuest }),
          ...boundsQueryObject,
        },
      },
      undefined,
      { shallow: pathname === routes.properties }
    );
  };

  const onClickSuggestForm = () => {
    if (search.properties.length > 0 || search.areas.length > 0) {
      setShowSuggestResult(true);
    } else {
      setShowSearchHistory(true);
    }
    pushDataLayer({
      event: 'search_box_destination_click',
    });
  };

  const onSearchConditionButton = () => {
    setShowPropertiesSearchModal(true);
    pushDataLayer({
      event: 'search_box_click',
    });
  };

  const onClickSuggestItem = (
    item: any,
    itemType: 'Property' | 'Area',
    order: number,
    isHistory: boolean
  ) => {
    setShowSearchHistory(false);
    setSearchHistory(item, itemType);

    if (!isHistory) {
      pushDataLayer({
        event: 'search_destination_suggestion_click',
        order,
        search_suggest_title: item.name,
      });
    }

    if (itemType === 'Property') {
      const localePath = locale === defaultLocale ? '' : `/${locale}`;
      const propertyPath = `${localePath}${route(routes.property, {
        id: item.id,
      })}`;
      window.open(propertyPath, '_blank');

      return;
    }

    setCurrentItem(item);
    setCurrentItemType(itemType);
    setLocationQuery(item.name);
  };

  const onClickDateForm = () => {
    setShowCalendar(true);
    pushDataLayer({
      event: 'search_box_date_click',
    });
  };

  const onClickGuestForm = () => {
    setShowGuests(true);
    pushDataLayer({
      event: 'search_box_guest_click',
    });
  };

  const onClickCalendarSearch = () => {
    pushToPropertiesPage(currentItem, currentItemType);
    setShowPropertiesSearchModal(false);
  };

  const onClickSearchIcon = async () => {
    const defaultBehaviour = async () => {
      const defaultPrefecture = getDefaultPrefecture();
      const { areas } = await autoComplete(defaultPrefecture, locale);
      pushToPropertiesPage(areas[0], currentItemType);
    }

    if (!locationQuery) {
      await defaultBehaviour();
    } else if (!currentItem && search.areas.length > 0) {
      pushToPropertiesPage(search.areas[0], 'Area');
      setLocationQuery(search.areas[0].name);
    } else if (!currentItem && search.properties.length > 0) {
      pushToPropertiesPage(search.properties[0], 'Property');
      setLocationQuery(search.properties[0].name);
    } else if (currentItem) {
      pushToPropertiesPage(currentItem, currentItemType);
    } else {
      await defaultBehaviour();
    }

    setShowCalendar(false);

    pushDataLayer({
      event: 'search_execution_click',
    });
  };

  useHandleOutsideClick(
    '.auto-suggest-form-area',
    () => {
      setShowSuggestResult(false);
      setShowSearchHistory(false);
    },
    [showSuggestResult, showSearchHistory]
  );

  const areaPlaceHolder =
    pathname === routes.properties && locationSearchEnabled
      ? t('propertiesInMap')
      : t('areaPlaceholder');

  const dateString =
    startDate && endDate
      ? `${formatCalendarDate(startDate, locale)} - ${formatCalendarDate(
          endDate,
          locale
        )}`
      : '';

  useEffect(() => {
    if (area_name) {
      setLocationQuery(area_name);
    }

    if (isReady) {
      setDateRange({
        endDate: checkin && checkout ? moment(checkout) : null,
        startDate: checkin && checkout ? moment(checkin) : null,
      });
      setNoOfGuest(
        no_of_guest ? Number.parseInt(no_of_guest as string, 10) : 0
      );
    }
  }, [isReady]);

  const onClickClear = () => {
    setDateRange({
      endDate: null,
      startDate: null,
    });

    setUndecidedDate(false);
  };

  const onClickClearGuest = useCallback(() => {
    setNoOfGuest(0);
    pushDataLayer({
      event: 'search_guest_filter_clear_click',
    });
  }, []);

  const onClickOfDecrement = () => {
    pushDataLayer({
      event: 'search_guest_filter_decrement_click',
    });
  };

  const onClickOfIncrement = () => {
    pushDataLayer({
      event: 'search_guest_filter_increment_click',
    });
  };

  useEffect(() => {
    (async () => {
      if (areaNameQuery) {
        const { areas } = await autoComplete(area_name, locale);

        if (areas?.length > 0) {
          setShortAreaName(areas[0].short_name);
        }
      } else {
        setShortAreaName('');
        setLocationQuery('');
      }
    })();
  }, [area_name]);
  useHandleOutsideClick(
    '.auto-suggest-form-date-calendar',
    () => {
      setShowCalendar(false);
    },
    [showCalendar]
  );

  useHandleOutsideClick(
    '.auto-suggest-form-guest-counter',
    () => {
      setShowGuests(false);
    },
    [showGuests]
  );

  return (
    <>
      <Device mobile={true} tablet={true}>
        <PropertiesSearchModal
          dateRange={dateRange}
          locationQuery={locationQuery}
          noOfGuest={noOfGuest}
          onChangeAreaAutoSuggest={onChangeAreaAutoSuggest}
          onClickSearch={onClickCalendarSearch}
          searchQueryParams={query}
          setDateRange={setDateRange}
          setLocationQuery={setLocationQuery}
          setNoOfGuest={setNoOfGuest}
          setShow={setShowPropertiesSearchModal}
          setShowAutoSuggestMobile={setShowAutoSuggestMobile}
          setUndecidedDate={setUndecidedDate}
          show={showPropertiesSearchModal}
          showAutoSuggestMobile={showAutoSuggestMobile}
          undecidedDate={undecidedDate}
        />
      </Device>
      <div
        className={classNames('auto-suggest-container', {
          'has-background': hasBackground,
        })}
      >
        <div className="auto-suggest-form">
          <div className="auto-suggest-form-area">
            <Device desktop={true}>
              <Input
                autoComplete="off"
                customRef={areaInputRef}
                icon={<IconPin />}
                name="area"
                onChange={onChangeAreaAutoSuggest}
                onClick={onClickSuggestForm}
                onClickDeleteButton={() => setLocationQuery('')}
                placeHolder={areaPlaceHolder}
                showDeleteButton={!!locationQuery}
                valueObj={{ value: locationQuery }}
              />
            </Device>
            <Device mobile={true} tablet={true}>
              <SearchConditionButton
                endDate={endDate}
                guestUnit={t('guestUnit')}
                locale={locale}
                noOfGuest={noOfGuest}
                onClick={onSearchConditionButton}
                placeHolderText={t('areaPlaceholder')}
                shortAreaName={shortAreaName}
                startDate={startDate}
              />
            </Device>
          </div>
          <Device desktop={true}>
            <div className="auto-suggest-form-date">
              <Input
                autoComplete="off"
                icon={<IconCalendar />}
                isPlaceholderSameStyleOfValue={undecidedDate}
                name="date"
                onClick={onClickDateForm}
                placeHolder={t('datePlaceholder')}
                valueObj={{ value: dateString }}
              />
            </div>
            <div className="auto-suggest-form-guest">
              <Input
                autoComplete="off"
                icon={<IconMultiUsers />}
                isPlaceholderSameStyleOfValue={undecidedDate}
                name="guests"
                onClick={onClickGuestForm}
                placeHolder={t('guestPlaceholder')}
                valueObj={{
                  value: (noOfGuest && `${noOfGuest} ${t('guestUnit')}`) || '',
                }}
              />
            </div>
            <button
              className="search-button"
              onClick={onClickSearchIcon}
              type="button"
            >
              <TypoXSBold color="white">{t('searchButton')}</TypoXSBold>
            </button>
            <AutoSuggestList
              key="desktop"
              onClickSuggestItem={onClickSuggestItem}
              searchHistories={searchHistories}
              showSearchHistory={showSearchHistory}
              showSuggestResult={showSuggestResult}
            />
            <div className="auto-suggest-form-date-calendar">
              <Modal
                centerButtonLabel={t('anyDates')}
                centerButtonStyle="clear"
                maxWidth="none"
                onClickCenterButton={onClickClear}
                position="absolute"
                setShow={setShowCalendar}
                show={showCalendar}
                showHeader={false}
              >
                <SearchCalendar
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  setUndecidedDate={setUndecidedDate}
                  undecidedDate={undecidedDate}
                />
              </Modal>
            </div>
            <div className="auto-suggest-form-guest-counter">
              <Modal
                headerLabel={t('selectGuestCount')}
                leftButtonLabel={t('clear')}
                maxWidth="none"
                onClickCloseButton={() => {
                  pushDataLayer({
                    event: 'search_guest_filter_close_click',
                  });
                }}
                onClickLeftButton={onClickClearGuest}
                onClickRightButton={() => {
                  setNoOfGuest(noOfGuest);
                  setShowGuests(false);
                  pushDataLayer({
                    event: 'search_guest_filter_select_click',
                  });
                }}
                position="absolute"
                rightButtonLabel={t('setGuest')}
                setShow={setShowGuests}
                show={showGuests}
                showFooter={false}
                showHeader={false}
              >
                <InputStepper
                  label={t('guestTitle')}
                  maxNumber={MAX_GUEST_COUNT_FOR_SEARCH}
                  onClickOfDecrement={onClickOfDecrement}
                  onClickOfIncrement={onClickOfIncrement}
                  setNoOfGuest={setNoOfGuest}
                  value={noOfGuest}
                />
              </Modal>
            </div>
          </Device>
        </div>
      </div>
      <style jsx={true}>{styles}</style>
    </>
  );
};

export default AutoSuggestInHeader;
