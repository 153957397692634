import React from 'react';
import IconColoredMultiUsers from '@assets/new/icon-colored-multi-users.svg';
import { SVG_SIZES } from '@utils/constants';
import classNames from 'classnames';
import styles from './css';

type IconProps = {
  color?: 'black900' | 'blue' | 'gray';
  size?: 'xs' | 'sm' | 'md' | 'lg';
  withFill?: boolean;
};

const IconMultiUsers = ({
  color,
  size = 'md',
  withFill = true,
}: IconProps = {}) => {
  const style = {
    height: SVG_SIZES[size],
    width: SVG_SIZES[size],
  };

  return (
    <div>
      <IconColoredMultiUsers
        className={classNames(`color-${color} icon-multi-user`, {
          'no-fill': !withFill,
        })}
        style={style}
      />
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default IconMultiUsers;
